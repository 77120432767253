<template>
  <div id="packAndCheck">
    <!-- 顶部筛选 -->
    <div class="filter">
      <!-- <div class="filterItem">
        <div style="margin-right:10px;min-width:56px">坯布指示单号</div>
        <Input
          placeholder="请输入搜索内容"
          style="width: 210px"
          v-model="listData.searchText"
        />
      </div>
      <div class="filterItem">
        <div style="margin-right:10px;min-width:26px">货号</div>
        <Input
          placeholder="请输入搜索内容"
          style="width: 210px"
          v-model="listData.searchText"
        />
      </div> -->
      <!-- <div class="filterItem">
        <div style="margin-right:10px;min-width:56px">交货日期</div>
        <DatePicker
          :value="listData.dateRange"
          format="yyyy-MM-dd"
          type="daterange"
          placeholder="请选择日期范围"
          style="width: 212px"
        ></DatePicker>
      </div> -->
      <div class="filterItem">
        <div style="margin-right:10px;min-width:56px">计划单号</div>
        <Input
          placeholder="请输入搜索内容"
          style="width: 210px"
          v-model.trim="planOrderNumber"
          @keyup.enter.native="getTableDataList"
        />
      </div>
      <div class="filterItem">
        <div style="margin-right:10px;min-width:36px">合同号</div>
        <Input
          placeholder="请输入搜索内容"
          style="width: 210px"
          v-model.trim="contractNo"
          @keyup.enter.native="getTableDataList"
        />
      </div>
      <div class="filterItem">
        <div style="margin-right:10px;min-width:36px">品号</div>
        <Input
          placeholder="请输入搜索内容"
          style="width: 210px"
          v-model.trim="articleNumber"
          @keyup.enter.native="getTableDataList"
        />
      </div>
      <div class="filterItem">
        <div style="margin-right:10px;min-width:56px">面料颜色</div>
        <Input
          placeholder="请输入搜索内容"
          style="width: 210px"
          v-model.trim="fabricColor"
          @keyup.enter.native="getTableDataList"
        />
      </div>
      <!-- <div class="filterItem">
        <Button @click="customFilter"
          >自定义筛选
          <i class="iconfont iconicon-xia"></i>
        </Button>
      </div> -->
    </div>
    <!-- 自定义筛选选择 -->
    <div class="custom" v-if="customShow">
      <div class="triangle"></div>
      <div class="check" v-if="customSetting">
        <Checkbox
          :indeterminate="indeterminate"
          :value="checkAll"
          @click.prevent.native="handleCheckAll"
          >全选</Checkbox
        >
        <div class="border"></div>
        <CheckboxGroup v-model="checkAllGroup" @on-change="checkAllGroupChange">
          <Checkbox
            v-for="item in checkList"
            :key="item.index"
            :label="item.label"
          ></Checkbox>
        </CheckboxGroup>
        <div class="btn">
          <!-- <Button @click="cancelSetting">取消</Button> -->
          <Button type="primary" @click="confirmSetting">确定</Button>
        </div>
      </div>
      <!-- 自定义筛选确认后 -->
      <div class="searchSetting" v-if="alreadySetting">
        <div class="settingFilter">
          <div class="filterItem" v-for="item in selectedCheckList" :key="item">
            <div style="margin-right:10px">{{ item.label }}</div>
            <Input
              placeholder="请输入搜索内容"
              style="width: 212px"
              v-model.trim="listData.params[item.key]"
              @keyup.enter.native="getTableDataList"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="button">
      <div class="buttonRow">
        <div class="left">
          <div class="item" @click="refresh">
            <i class="iconfont iconicon-shuaxin"></i>
            刷新
          </div>
          <div class="item" @click="syncTableData">
            <i class="iconfont iconicon-tongbu"></i>
            同步
          </div>
          <div class="item" @click="showTableTitleDialog">
            <i class="iconfont iconicon-chuansuokuang"></i>
            表格设置
          </div>
          <div class="item"></div>
          <div class="item" @click="exportPackAndCheckList">
            <i class="iconfont iconicon-daochu"></i>
            导出
          </div>
          <div class="item" @click="getTableDataList">
            <i class="iconfont iconquerenshaixuan"></i>
            确认筛选
          </div>
        </div>
      </div>
    </div>
    <Table
      :columns="tableColumns"
      :data="tableData"
      border
      :loading="loading"
    ></Table>
    <Page
      class-name="page"
      :total="listData.total"
      show-elevator
      show-sizer
      show-total
      :page-size="listData.pageSize"
      :current="listData.pageNum"
      @on-page-size-change="pageSizeChange"
      @on-change="pageNumberChange"
    />
    <!-- 穿梭框的弹出框 -->
    <TableSetting
      :chuansuoDialogVisible.sync="chuansuoDialogVisible"
      :routerPath="routerPath"
      :theadDateReal="tableColumns"
      @tableDataList="getTableDataList"
    ></TableSetting>
    <!-- <el-drawer
      title="包装检验细表"
      :visible.sync="drawerShow"
      direction="btt"
    >
      <div class="button">
        <div class="buttonRow">
          <div class="left">
            <div class="item">
              <i class="iconfont iconicon-shuaxin"></i>
              刷新
            </div>
            <div class="item" @click="showTableTitleDialog">
              <i class="iconfont iconicon-chuansuokuang"></i>
              表格设置
            </div>
          </div>
        </div>
      </div>
      <Table
        :columns="detailsTableColumns"
        :data="detailsTableData"
        border
      ></Table>
      <Page
        class-name="page"
        :total="detailsListData.total"
        show-elevator
        show-sizer
        show-total
        :page-size="detailsListData.pageSize"
        :current="detailsListData.pageNum"
        @on-page-size-change="pageSizeDetailsChange"
        @on-change="pageNumberDetailsChange"
      />
    </el-drawer> -->
  </div>
</template>

<script>
import TableSetting from "../../components/home/tableSetting.vue";
export default {
  components: {
    TableSetting
  },
  data() {
    return {
      loading: true,
      articleNumber: "",
      planOrderNumber: "",
      contractNo: "",
      fabricColor: "",
      routerPath:
        "/dtsum/zongtong/greycloth/greyClothController/selectAllClothTest",
      theadDateReal: [],
      chuansuoDialogVisible: false, // 表格设置显示

      userFilds: [],
      searchList: [
        {
          index: 1,
          name: "坯布指示单号"
        },
        {
          index: 2,
          name: "坯布货号"
        },
        {
          index: 3,
          name: "单据编号"
        }
      ],
      typeList: [
        {
          index: -1,
          name: "全部"
        },
        {
          index: 1,
          name: "原材料加工出仓"
        },
        {
          index: 2,
          name: "织造加工出仓"
        },
        {
          index: 3,
          name: "回修出仓"
        },
        {
          index: 4,
          name: "盘亏出仓"
        },
        {
          index: 5,
          name: "退货出仓"
        },
        {
          index: 6,
          name: "退货进仓"
        }
      ],
      customShow: false,
      customSetting: false,
      alreadySetting: false,
      indeterminate: false,
      checkAll: false,
      checkAllGroup: [],
      selectedCheck: [], // 已选中的可选条件组成的数组
      selectedCheckList: [], // 已选中的可选条件组成的数组list
      checkList: [
        {
          index: 1,
          label: "面料颜色",
          key: "fabricColor"
        }
      ],
      listData: {
        pageSize: 10,
        pageNum: 1,
        total: 0
      },
      tableColumnsReady: [
        {
          title: "序号",
          render: (h, params) => {
            return h(
              "span",
              params.index +
                (this.listData.pageNum - 1) * this.listData.pageSize +
                1
            );
          },
          width: 80,
          align: "center"
        },
        {
          title: "布卷条码",
          key: "ztgctClothBarCode",
          minWidth: 150,
          align: "left"
        },
        {
          title: "审核",
          key: "ztgctExamine",
          minWidth: 65,
          align: "left"
        },
        {
          title: "卷号",
          key: "ztgctReelNumber",
          minWidth: 80,
          align: "left"
        },
        {
          title: "重量",
          key: "ztgctWeight",
          minWidth: 100,
          align: "left"
        },
        {
          title: "产品条码",
          key: "ztgctUpcCode",
          minWidth: 150,
          align: "left"
        },
        {
          title: "检验员",
          key: "ztgctTestPerson",
          minWidth: 100,
          align: "left"
        },
        {
          title: "挡车工",
          key: "ztgctWorkPerson",
          minWidth: 100,
          align: "left"
        },
        {
          title: "合同号",
          key: "ztgctContractNo",
          minWidth: 150,
          align: "left"
        },
        {
          title: "品名",
          key: "ztgctProductName",
          minWidth: 200,
          align: "left"
        },
        {
          title: "批次",
          key: "ztgctBatch",
          minWidth: 200,
          align: "left"
        },
        {
          title: "品号",
          key: "ztgctArticleNumber",
          minWidth: 150,
          align: "left"
        },
        {
          title: "客户款号",
          key: "ztgctModelNumber",
          minWidth: 200,
          align: "left"
        },
        {
          title: "检验日期",
          key: "ztgctTestDate",
          minWidth: 200,
          align: "left"
        },
        {
          title: "生产日期1",
          key: "ztgctProductionDate1",
          minWidth: 200,
          align: "left"
        },
        {
          title: "生产日期2",
          key: "ztgctProductionDate2",
          minWidth: 200,
          align: "left"
        },
        {
          title: "匹数",
          key: "ztgctClothQuantity",
          minWidth: 100,
          align: "left"
        },
        {
          title: "转数1",
          key: "ztgctWhirlNum1",
          minWidth: 100,
          align: "left"
        },
        {
          title: "转数2",
          key: "ztgctWhirlNum2",
          minWidth: 100,
          align: "left"
        },
        {
          title: "重量1",
          key: "ztgctWeight1",
          minWidth: 100,
          align: "left"
        },
        {
          title: "重量2",
          key: "ztgctWeight2",
          minWidth: 100,
          align: "left"
        },
        {
          title: "挡车工2",
          key: "ztgctWorkPerson2",
          minWidth: 100,
          align: "left"
        },
        {
          title: "客户编号",
          key: "ztgctCustomerNumber",
          width: 100,
          align: "left"
        },
        {
          title: "机台号",
          key: "ztgctDeviceNumber",
          minWidth: 100,
          align: "left"
        },
        {
          title: "计划单号",
          key: "ztgctPlanOrderNumber",
          minWidth: 150,
          align: "left"
        },
        {
          title: "疵点数",
          key: "ztgctFlawNum",
          minWidth: 100,
          align: "left"
        },
        {
          title: "总转数",
          key: "ztgctTotalWhirlNum",
          minWidth: 100,
          align: "left"
        },
        {
          title: "称重仪",
          key: "ztgctWeighingInstrument",
          minWidth: 100,
          align: "left"
        },
        {
          title: "系数",
          key: "ztgctCoefficient",
          minWidth: 100,
          align: "left"
        },
        {
          title: "称重重量",
          key: "ztgctWeighingWeight",
          minWidth: 100,
          align: "left"
        },
        {
          title: "送数",
          key: "ztgctSendNum",
          minWidth: 100,
          align: "left"
        },
        {
          title: "班次",
          key: "ztgctShift",
          minWidth: 100,
          align: "left"
        },
        {
          title: "备注",
          key: "ztgctRemarks",
          minWidth: 200,
          align: "left"
        },
        {
          title: "等级",
          key: "ztgctGrade",
          minWidth: 100,
          align: "left"
        },
        {
          title: "门幅",
          key: "ztgctLarghezza",
          minWidth: 150,
          align: "left"
        },
        {
          title: "克重",
          key: "ztgctGramWeight",
          minWidth: 100,
          align: "left"
        },
        {
          title: "色系",
          key: "ztgctColorSystem",
          minWidth: 100,
          align: "left"
        },
        {
          title: "详细日期",
          key: "ztgctDetailedDate",
          minWidth: 200,
          align: "left"
        },
        {
          title: "疵点",
          key: "ztgctFlaw",
          minWidth: 200,
          align: "left"
        },
        {
          title: "规格",
          key: "ztgctSpecifications",
          minWidth: 150,
          align: "left"
        },
        {
          title: "机型",
          key: "ztgctModel",
          minWidth: 100,
          align: "left"
        },
        {
          title: "单位",
          key: "ztgctUnit",
          minWidth: 100,
          align: "left"
        },
        {
          title: "面料颜色",
          key: "ztgctFabricColor",
          minWidth: 200,
          align: "left"
        },
        {
          title: "检验机台",
          key: "ztgctInspectionMachine",
          minWidth: 200,
          align: "left"
        },
        {
          title: "匹号",
          key: "ztgctClothNumber",
          minWidth: 200,
          align: "left"
        },
        {
          title: "计划单号检验",
          key: "ztgctPlanNumberTest",
          minWidth: 150,
          align: "left"
        },
        {
          title: "操作日志",
          key: "ztgctOperationLog",
          minWidth: 800,
          align: "left"
        }
      ],
      tableColumns: [],
      tableData: [],
      drawerShow: false,
      detailsListData: {
        pageSize: 10,
        pageNum: 1,
        total: 40
      }
    };
  },
  mounted() {
    this.getTableDataList();
  },
  activated(){
    this.getTableDataList();
  },
  methods: {
    // 刷新表格
    refresh() {
      this.loading = true;
      this.getTableDataList();
    },
    // 同步表格数据
    syncTableData() {
      this.loading = true;
      this.axios({
        url: "/dtsum/zongtong/basic/CommonController/synTable",
        method: "get"
      })
        .then((res) => {
          if (res.data.success === 1) {
            this.$Message.success("同步成功！");
            this.getTableDataList();
          } else {
            this.$Message.warning("同步失败！");
          }
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 导出
    exportPackAndCheckList() {
      window.location.href = `${this.baseUrl}/dtsum/zongtong/greycloth/greyClothController/exportAllClothTest?planOrderNumber=${this.planOrderNumber}&articleNumber=${this.articleNumber}&contractNo=${this.contractNo}&fabricColor=${this.fabricColor}`;
    },
    // 获取表格信息
    getTableDataList() {
      this.tableColumns.length = 0;
      this.axios({
        url: "dtsum/zongtong/greycloth/greyClothController/selectAllClothTest",
        method: "get",
        params: {
          pageSize: this.listData.pageSize,
          pageNum: this.listData.pageNum,
          params: {
            planOrderNumber: this.planOrderNumber, // 计划单号
            articleNumber: this.articleNumber, // 品号
            contractNo: this.contractNo, // 合同号
            fabricColor: this.fabricColor // 面料颜色
          }
        }
      })
        .then((res) => {
          if (res.data.success === 1) {
            if (this.tableColumns.length === 0) {
              this.tableData = JSON.parse(
                JSON.stringify(res.data.body.clothTestList.list)
              );
              this.tableColumns.push(this.tableColumnsReady[0]);
              this.userFilds = res.data.body.userFilds;
              this.userFilds.map((item) => {
                this.tableColumnsReady.map((itemIn) => {
                  if (item.field === itemIn.key) {
                    this.tableColumns.push(itemIn);
                  }
                });
              });
              this.listData.total = res.data.body.clothTestList.total;
              this.listData.pageSize = res.data.body.clothTestList.pageSize;
              this.listData.pageNum = res.data.body.clothTestList.pageNum;
            }
          }
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },
    pageSizeChange(pageSize) {
      this.listData.pageSize = pageSize;
      this.listData.pageNum = 1;
      this.getTableDataList();
    },
    pageNumberChange(pageNum) {
      this.listData.pageNum = pageNum;
      this.getTableDataList();
    },
    pageSizeDetailsChange(pageSize) {
      this.detailsListData.pageSize = pageSize;
      this.detailsListData.pageNum = 1;
    },
    pageNumberDetailsChange(pageNum) {
      this.detailsListData.pageNum = pageNum;
    },
    // 显示弹出框
    showTableTitleDialog() {
      this.chuansuoDialogVisible = true;
    },
    handleCheckAll() {
      if (this.indeterminate) {
        this.checkAll = false;
      } else {
        this.checkAll = !this.checkAll;
      }
      this.indeterminate = false;
      if (this.checkAll) {
        this.checkAllGroup = this.checkList.map(function(item) {
          return item.label;
        });
      } else {
        this.checkAllGroup = [];
      }
    },
    checkAllGroupChange(data) {
      if (data.length === this.checkList.length) {
        this.indeterminate = false;
        this.checkAll = true;
      } else if (data.length > 0) {
        this.indeterminate = true;
        this.checkAll = false;
      } else {
        this.indeterminate = false;
        this.checkAll = false;
      }
    },
    // 点击自定义筛选
    customFilter() {
      if (this.selectedCheck.length > 0) {
        this.customShow = true;
        if (this.alreadySetting) {
          this.customSetting = true;
          this.alreadySetting = false;
          this.checkAllGroup = this.selectedCheck;
          if (this.checkList.length !== this.checkAllGroup.length) {
            this.indeterminate = true;
          } else {
            this.indeterminate = false;
            this.checkAll = true;
          }
        } else {
          this.customSetting = false;
          this.alreadySetting = true;
        }
      } else {
        this.customShow = !this.customShow;
        this.checkAllGroup = this.selectedCheck;
        this.indeterminate = false;
      }
    },
    // 确定自定义筛选
    confirmSetting() {
      this.selectedCheck = this.checkAllGroup;
      if (this.selectedCheck.length > 0) {
        this.customSetting = false;
        this.alreadySetting = true;
        this.customShow = true;
        var arr = [];
        this.selectedCheck.forEach((item) => {
          this.checkList.forEach((item1) => {
            if (item === item1.label) {
              arr.push(item1);
            }
          });
        });
        this.selectedCheckList = arr;
      } else {
        this.customSetting = true;
        this.alreadySetting = false;
        this.customShow = false;
        this.indeterminate = false;
      }
    }
  }
};
</script>

<style lang="scss">
#packAndCheck {
  .filter {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 32px;
    font-size: $--fontsize-small;
    .filterItem {
      display: inline-flex;
      margin-right: 50px;
      margin-bottom: 20px;
      height: 32px;
      line-height: 32px;
      i {
        margin-left: 9px;
      }
      // &:last-child {
      //   width: 132px;
      //   margin-right: 0px;
      //   button {
      //     position: absolute;
      //     right: 0px;
      //   }
      // }
    }
  }
  .custom {
    position: relative;
    width: 100%;
    // min-height: 95px;
    background: #f5f5f5;
    margin-top: -35px;
    margin-bottom: 39px;
    padding: 16px 8px;
    .triangle {
      position: absolute;
      top: -14px;
      right: 60px;
      width: 0px;
      height: 0px;
      border-top: 0px solid transparent;
      border-left: 14px solid transparent;
      border-right: 14px solid transparent;
      border-bottom: 14px solid #f5f5f5;
    }
    .border {
      display: inline-block;
      width: 1px;
      height: 14px;
      background: #dcdada;
      margin-right: 20px;
      vertical-align: middle;
    }
    .ivu-checkbox-wrapper {
      margin-right: 20px;
    }
    .ivu-checkbox-group {
      display: inline-block;
    }
    .btn {
      margin-top: 17px;
      text-align: right;
      button {
        &:nth-child(1) {
          margin-right: 41px;
        }
      }
    }
  }
  .searchSetting {
    display: flex;
    justify-content: space-between;
    margin-bottom: -20px;
    .settingFilter {
      display: flex;
      flex-wrap: wrap;
      font-size: $--fontsize-small;
      .filterItem {
        display: inline-flex;
        margin-right: 50px;
        margin-bottom: 20px;
        height: 32px;
        line-height: 32px;
        &:last-child {
          margin-right: 0px;
        }
      }
    }
    .settingBtn {
      display: flex;
      line-height: 32px;
      .border {
        width: 1px;
        height: 14px;
        margin-right: 14px;
        background: #dcdada;
        margin-top: 9px;
      }
      .changebtn {
        color: #256de6;
        cursor: pointer;
      }
    }
  }
  .button {
    font-size: $--fontsize-small;
    margin-bottom: 20px;
    margin-top: -20px;
    .buttonRow {
      display: flex;
      justify-content: space-between;
      margin: 10px 0;
      .left {
        .item {
          display: inline-block;
          margin-right: 30px;
          color: $--color-blue-standard;
          cursor: pointer;
          &:nth-child(3) {
            margin-right: 20px;
          }
          &:nth-child(4) {
            width: 1px;
            height: 14px;
            background: #dcdada;
            margin-right: 20px;
            vertical-align: middle;
          }
        }
      }
      .right {
        .item {
          margin-left: 10px;
        }
        .filterButton{
          width:130px;
          height:32px;
          line-height:32px;
          text-align: center;
        }
      }
    }
  }
  .page {
    margin-top: 10px;
    text-align: center;
  }
  .el-drawer {
    height: 60% !important;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    .el-drawer__header {
      margin-bottom: 12px;
      padding: 20px 30px 0px 30px;
      font-size: 16px;
      color: #212b36;
    }
    .el-drawer__body {
      height: 60% !important;
      overflow: auto;
      padding: 0px 30px 30px 30px;
      .button {
        margin-top: 0px;
        margin-bottom: 17px;
      }
    }
  }
}
</style>
